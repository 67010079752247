import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryParamDTO } from '../../shared/dto/table.dto';
import { MainCRUDServiceInterface } from '../interfaces/service/main-crud-service.interface';

export abstract class BaseService<TDTO, TDetailsDTO = any, TFormDTO = any>
  implements MainCRUDServiceInterface
{
  protected abstract apiUrl: string;

  constructor(protected http: HttpClient) {}

  list(
    sortField: string = '',
    sortOrder: string = 'asc',
    currentPage: number = 1,
    pageSize: number = 10,
    searchQuery?: string,
    quickFilterKey?: string,
    dynamicFilters?: { [key: string]: number[] },
    extraQueryParams?: QueryParamDTO[]
  ): Observable<TDTO[]> {
    let params = new HttpParams()
      .set('sort_by', sortField)
      .set('sort_order', sortOrder)
      .set('page', currentPage.toString())
      .set('page_size', pageSize.toString());

    if (searchQuery) {
      params = params.set('keyword', searchQuery);
    }

    if (quickFilterKey) {
      params = params.set('quick_filter', quickFilterKey);
    }

    if (dynamicFilters) {
      Object.keys(dynamicFilters).forEach((key) => {
        const values = dynamicFilters[key];
        if (values && values.length > 0) {
          params = params.set(key, values.join(','));
        }
      });
    }

    if (extraQueryParams) {
      extraQueryParams.forEach((param) => {
        params = params.set(param.key, param.value);
      });
    }

    return this.http.get<TDTO[]>(`${this.apiUrl}`, { params });
  }

  create(item: TFormDTO): Observable<TFormDTO> {
    return this.http.post<TFormDTO>(`${this.apiUrl}`, item);
  }

  get(id: number): Observable<TDetailsDTO> {
    return this.http.get<TDetailsDTO>(`${this.apiUrl}/${id}`);
  }

  update(id: number, item: TFormDTO): Observable<TFormDTO> {
    return this.http.put<TFormDTO>(`${this.apiUrl}/${id}`, item);
  }

  activate(ids: number[]): Observable<any> {
    return this.http.put(`${this.apiUrl}/activate`, { ids });
  }

  deactivate(ids: number[]): Observable<any> {
    return this.http.put(`${this.apiUrl}/deactivate`, { ids });
  }

  delete(ids: number[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/delete`, { ids });
  }
}
